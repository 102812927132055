Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  list-style-type: none;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 16px;

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    padding: 12px;
    list-style-type: none;
  }
}
