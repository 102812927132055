.Clients {
  text-align: center;
  font-size: 42px;
  padding: 124px 0 248px;

  ul {
    padding: 0;
  }

  li {
    display: block;
    margin: 32px 0;

    &:last-child {
      margin-top: 64px;
      font-size: 32px !important;

      a:after {
        bottom: 2px;
        transform: scaleX(1) !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 64px 0;
  }
}
