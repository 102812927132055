@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Open+Sans:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Infant:ital,wght@0,400;0,500;1,400;1,600&display=swap");

$background: #14142b;

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

a {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 22px;
}

* {
  font-family: "Cormorant Infant", sans-serif;
}

p {
  line-height: 1.5;
  font-weight: 500;
  font-family: "Cormorant Infant", sans-serif;
}

h1,
h2 {
  font-size: 48px;
  font-weight: 400;
  font-family: "Cormorant Infant", serif;
  letter-spacing: 4px;
}
