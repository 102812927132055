.Gallery {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 62%;
  margin: 120px auto;
  gap: 24px;

  div {
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 300px;
    border-radius: 4px;
    overflow: hidden;

    object-fit: cover;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 48% 48%;
    width: 80%;
  }

  @media screen and (max-width: 568px) {
    grid-template-columns: 1fr;
    width: 80%;

    img {
      height: auto;
    }
  }
}
