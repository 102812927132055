.AnimatedLink {
  text-decoration: none;
  color: black;
  position: relative;
  display: inline-block;

  &:after {
    position: absolute;
    width: 100%;
    height: 1px;
    content: "";
    background-color: #daa520;
    left: 0;
    bottom: 6px;
    transform: scaleX(0);
    transition: 300ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: -1;
  }

  &:hover:after {
    transform: scaleX(1);
  }
}
