* {
  box-sizing: border-box;
}

$color-gold: #daa520;
$color-gold-bg: #f4e4bb;

.Home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.Content {
  padding: 32px 32px 128px;

  > .Container {
    max-width: 1024px;
    margin: 0 auto;

    p {
      line-height: 2;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 32px 16px 128px;

    > .Container p {
      text-align: justify;
    }
  }
}

.Header {
  display: flex;
  align-items: center;

  img {
    margin-right: 48px;
  }
}

.Intersection {
  text-align: center;
  background-color: #fff;
  padding: 128px 32px 128px;
  z-index: 10;
  position: relative;
  border-top: 1px solid $color-gold-bg;

  p {
    margin: 0 auto;
    text-align: center;
    width: 80%;
    line-height: 2;
    max-width: 1024px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    b {
      color: #daa520;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 128px 16px 128px;

    p {
      text-align: justify;
    }
  }
}

.Layout-vignette {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  box-shadow: inset 0 -40px 156px rgba(219, 165, 35, 0.2);
  pointer-events: none;
  z-index: 10;
}

.LineContainer {
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  z-index: -1;

  .line {
    width: 1px;
    opacity: 1;
    transition: all 300ms 0ms cubic-bezier(0.16, 1, 0.3, 1);

    &:nth-child(1) {
      transition-delay: 100ms;
    }
    &:nth-child(2) {
      transition-delay: 220ms;
    }
    &:nth-child(3) {
      transition-delay: 340ms;
    }
    &:nth-child(4) {
      transition-delay: 460ms;
    }

    &.gold {
      background-color: rgba(black, 0.08);
    }
  }
}

.HeroButton {
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  z-index: 30;
  opacity: 0;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    left: 50%;
    top: 80%;
  }
}

.SecretTitle {
  color: rgba(black, 0.8);
  font-size: 20vw;
  position: absolute;
  width: 100vw;
  text-align: center;
  left: 50%;
  top: 50%;
  line-height: 1;
  transform: translate(-50%, -50%);
  z-index: 10;
  margin: 0;
  opacity: 0;
  transition: all 1680ms 0ms cubic-bezier(0.16, 1, 0.3, 1);
  pointer-events: none;

  animation: titleOut 1000ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  @keyframes titleOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.Layout {
  display: flex;

  @media screen and (max-width: 1260px) {
    padding: 51px;
  }

  .Video {
    overflow: hidden;
    position: absolute;
    cursor: pointer;
    right: 0;
    opacity: 0;
    bottom: 5%;
    transform: translate(0%, -50%);
    transition: all 400ms cubic-bezier(0.16, 1, 0.3, 1);
    animation: videoOut 1.6s linear forwards;

    @media screen and (max-width: 940px) {
      display: none;
    }

    @keyframes videoOut {
      0% {
        right: 50%;
        transform: translate(50%, 0);
        opacity: 0;
      }
      49% {
        right: 0;
        opacity: 0;
        transform: translate(50%, 0);
      }
      50% {
        right: 0;
        opacity: 0;
        transform: translate(0%, 0);
      }
      100% {
        right: 0;
        transform: translate(5%, 0);
        opacity: 1;
      }
    }

    video {
      max-height: 90vh;
    }
  }
}

.Layout-full {
  .line {
    opacity: 0;
    animation: lineAnimation 120ms cubic-bezier(0.16, 1, 0.3, 1) forwards;

    &:nth-child(1) {
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: 200ms;
    }
    &:nth-child(3) {
      animation-delay: 340ms;
    }
    &:nth-child(4) {
      animation-delay: 490ms;
    }

    @keyframes lineAnimation {
      0% {
        opacity: 0;
        background-color: rgba(#daa520, 0);
      }

      100% {
        opacity: 1;
        background-color: rgba(#daa520, 0.3);
      }
    }
  }
  .SecretTitle {
    animation: titleIn 4000ms 1100ms cubic-bezier(0.16, 1, 0.3, 1) forwards;

    @keyframes titleIn {
      from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.02);
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
  .Video {
    right: 50%;
    transform: translate(50%, 0);
    animation: videoIn 4.6s 600ms forwards cubic-bezier(0.16, 1, 0.3, 1);
    display: block;
    pointer-events: none;

    @keyframes videoIn {
      from {
        opacity: 0;
        transform: translate(50%, 0) scale(0.92);
      }
      to {
        opacity: 1;
        transform: translate(50%, 0) scale(1);
      }
    }

    &:hover {
      opacity: 1;
    }

    video {
      max-height: 84vh;
    }
  }
  .HeroButton {
    animation: buttonIn 400ms 2000ms forwards linear;
    position: absolute;

    &:after {
      bottom: 3px !important;
      background-color: rgba(0, 0, 0, 0.5);
    }

    @keyframes buttonIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .Container {
    opacity: 0;
  }
}

.Dimensions {
  margin-top: 124px;
  font-style: italic;
  text-align: center;

  i {
    font-weight: bold;
  }

  p:first-child {
    margin-bottom: 0;
    font-size: 18px;
  }

  span {
    display: inline-block;
    margin-right: 16px;
  }
  p {
    font-size: initial;
    margin: 4px 0 0px;
    font-size: 24px;
    line-height: 1.28 !important;
  }

  b:not(:first-child) {
    margin-left: 16px;
    color: initial;
  }

  .Contact {
    margin-top: 64px;

    a {
      font-size: 26px;

      &:after {
        bottom: 0px !important;
        transform: scaleX(1);
      }
    }
  }
}

.nav {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    padding: 12px;
    list-style-type: none;
  }

  a {
    text-decoration: none;
    padding: 8px 16px;
    color: black;
  }
}
